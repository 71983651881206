<!-- template -->
<template>
  <div id="app">
    <!-- <Nav /> -->

    <div class="container">
      <div class="row">
        <div class="col-md-6 py-5 d-none d-md-block">
          <carousel />

          <h5 class="Copyright-2021-Dat">
            Copyright © 2021 Data Poem All rights reserved. Privacy and Legal
            Policies
          </h5>
        </div>

        <div class="col-md-6 py-5">
          <div class="auth-wrapper">
            <div class="auth-inner content-center">
              <div class="row">
                <div class="w-100 content-center mb-5">
                  <img
                    src="../assets/bitmap.png"
                    srcset="
                      ../assets/bitmap@2x.png 2x,
                      ../assets/bitmap@3x.png 3x
                    "
                    class="Bitmap"
                  />
                </div>

                <div class="w-100">
                  <div class="Forgot-your-password-">
                    Forgot your password?
                  </div>

                  <div class="Enter-your-registere center-block">
                    Dont't worry! It happens to the best of us. So many passwords to remember! Help is on the way.
                  </div>

                  <b-form @submit.stop.prevent="onSubmit" class="">
                    <div class="w-100 d-flex my-5">
                      <b-form-group
                        class="w-100 p-2"
                        id="example-input-group-reset-password"
                      >
                        <div class="d-flex w-100 p-0 row m-0 px-md-5 px-0">
                          <div class="w-100 p-0" v-if="!otpProcess">
                            <span
                              class="content-center inputIcon"
                              :class="{
                                invalid: $v.form.email.$error,
                                success:
                                  !$v.form.email.$error &&
                                  $v.form.email.$model == '',
                              }"
                              ><img src="@/assets/user-line.svg" alt="userIcon"
                            /></span>
                            <b-form-input
                              id="forgotpasswordInput"
                              name="example-input-1"
                              v-model="$v.form.email.$model"
                              :state="validateState('email')"
                              aria-describedby="input-1-live-feedback"
                              autocomplete="off"
                              placeholder="Registered Email Id"
                              style="width: 90%"
                              type="email"
                              class="form-control c-form-input m-0"
                              @input="[(errorMessage = false)]"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              id="input-1-live-feedback"
                              class="w-100"
                              >Invalid Email.</b-form-invalid-feedback
                            >
                          </div>

                          <div class="w-100 p-0 mt-5" v-else>
                            <span class="content-center inputIcon"
                              ><img src="@/assets/user-line.svg" alt="userIcon"
                            /></span>
                            <b-form-input
                              v-model="validateOTP"
                              aria-describedby="input-1-live-feedback"
                              autocomplete="off"
                              placeholder="Enter OTP"
                              style="width: 90%"
                              type="number"
                              class="form-control c-form-input m-0"
                            ></b-form-input>
                          </div>
                          <span
                            style="color: red;"
                            v-if="errorMessage"
                            class="mt-3"
                            >This Email is not registered with us.</span
                          >
                        </div>
                      </b-form-group>
                    </div>
                    <div
                      class="text-right mt-5 px-0 px-md-5"
                      v-if="!otpProcess"
                    >
                      <w-button
                        :class="{ resetPasswordLink: !validForm }"
                        :buttonLoader="loader"
                        :buttonText="buttonText"
                        :isDisable="!validForm"
                        @buttonClicked="onSubmit"
                        class="mr-2"
                      >
                      </w-button>
                    </div>
                    <div class="text-right mt-5 px-0 px-md-5" v-else>
                      <w-button
                        :class="{ resetPasswordLink: validateOTP.length !== 6 }"
                        :buttonLoader="submitButtonLoader"
                        :buttonText="'Submit'"
                        :isDisable="validateOTP.length !== 6"
                        @buttonClicked="resetPassword"
                        class="mr-2"
                      >
                      </w-button>
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { formMixin } from "@/mixins/form-mixin.js";
import Carousel from "@/components/Login/Carousel.vue";
import { required, email } from "vuelidate/lib/validators";
import Button from "../components/Profile/Button.vue";
import { LoginServices } from "../services/LoginServices.js";
const loginServices = new LoginServices();

export default {
  name: "ForgotPassword",

  mixins: [validationMixin, formMixin],

  components: {
    Carousel,
    "w-button": Button,
  },

  data() {
    return {
      buttonText: "Send Reset Link",
      loader: "normal",
      otpProcess: false,
      submitButtonLoader: "normal",
      errorMessage: false,
      validateOTP: "",
      form: {
        email: null,
      },
      validForm: false,
      loading: false,
    };
  },

  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },

  watch: {
    "$v.form.$anyError"(val) {
      if (!val) {
        this.validForm = true;
      } else {
        this.validForm = false;
      }
    },
  },

  methods: {
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.loader = "loader";
      this.buttonText = "Sending";
      this.loading = true;
      loginServices.resetPassword(this.form.email).then((res) => {
        if (res.status === "FAILED") {
          this.loader = "normal";
          this.errorMessage = true;
          return;
        }
        console.log(res);
        this.loader = "normal";
        this.otpProcess = true;
      });
      // this.$router.push("/reset-password");
    },
    resetPassword() {
      this.submitButtonLoader = "loader";
      loginServices.verifyOtp(this.form.email, this.validateOTP).then((res) => {
        if (res.status === "FAILED") {
          this.submitButtonLoader = "normal";
          return;
        }
        this.$router.push({
          name: "ResetPassword",
          params: {
            email: this.form.email,
            otp: this.validateOTP,
          },
        });
      });
    },
  },
};
</script>

<style scoped>
.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545 !important;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #a3b6d9 !important;
}
.inputIcon.invalid {
  color: #f8d8d8 !important;
  background-color: #f8d8d8 !important;
  border: 1px solid #dc3545;
  border-right: 0px;
}
.inputIcon.success {
  font-family: ProximaNovaBold;
  color: #3a424f;
}
.inputIcon {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #a3b6d9;
  background-color: #eff5ff;
  border: 1px solid;
  border-right: 0px;
  height: 60px;
  width: 10%;
  float: left;
}
.resetPasswordLink.disableButton {
  background-color: rgb(5, 5, 5, 0.2) !important;
  color: #ffffff !important;
}
* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  display: flex;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
}

body,
html,
#app,
#root,
.auth-wrapper {
  width: 100%;
  height: auto;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 546px;
  height: 860px;
  padding: 0px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #dadee4;
  background-color: #ffffff;
  position: relative;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.Bitmap {
  height: 170px;
  object-fit: contain;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.Forgot-your-password- {
  font-family: ProximaNovaBold;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
}

.Enter-your-registere {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #9aafd4;
  margin-top: 14px;
}

::placeholder {
  color: #9aafd4;
  opacity: 1; /* Firefox */
}

.password {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 485px;
  right: 450px;
  object-fit: contain;
  background-color: #f2f5fa;
}

.btn-effect {
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #cdcdcd;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  margin-top: 30px;
}

.btn-effect-valid {
  margin: 70px 0px 20px 65px;
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
}

.btn-effect i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect-valid i {
  font-size: 14px;
  float: right;
  margin-top: 7px;
  margin-right: 10px;
}

.btn-effect:hover {
  background-color: #050505;
  color: #f3f3f3;
}

.btn-loader {
  float: right;
  margin-right: 19px;
  border-top-width: 0.2rem;
  border-bottom-width: 0.2rem;
  border-right-width: 0.2rem;
  border-left-width: 0.2rem;
}

.isi {
  width: 190px;
  height: 60px;
  margin: 0 0 0 12px;
  object-fit: contain;
  opacity: 0.5;
}

.Copyright-2021-Dat {
  width: 434px;
  height: 16px;
  font-family: ProximaNovaBold;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  position: absolute;
  left: -30px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #9aafd4;
}

.bg-img {
  position: absolute;
  top: 0px;
  width: 228px;
  height: 265px;
  background-image: "./assets/Icons/path-17.svg";
}

/* form inputs */
.c-form-input {
  width: 90% !important;
  height: 60px !important;
  padding: 18px 18px 18px 6px !important;
  border-radius: 3px !important;
  background-color: #eff5ff !important;
  object-fit: contain;
  background-image: none;
  font-size: 16px;
  font-weight: bold;
  border-left: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -1px !important;
}

.c-form-input.is-invalid {
  background-color: #f8d8d8 !important;
}

.invalid-feedback {
  font-size: 14px;
}

.form-control:focus.is-invalid {
  border-color: #dc3545 !important;
}

@media screen and (max-width: 768px) {
  .auth-inner {
    width: 100%;
  }
}
@media screen and (max-width: 420px) {
  .Enter-your-registere {
    width: 290px;
  }
  .Forgot-your-password- {
    height: auto;
  }
}
</style>
